.home-container {
  /* position: relative;
  width: 100%; */
  font-family: "Roboto Mono", monospace;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.home-text {
  font-size: larger;
  font-family: "Roboto Mono", monospace;
  text-align: center;
}
.home-portfolio-img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.img-container {
  width: 100%;
  display: flex;
  padding-top: 200px;
}
.fingerprint {
  /* position:relative; */
  width: 180px;
  /* height: auto; */
  /* display: inline-block; */
  margin: auto;
}

/* .portfolio-link {
  display: "flex";
  text-decoration: "none";
  color: "black";
  border: "2px solid white";
  background-color: "white";
  justify-content: "center";
  align-items: "center";
  width: "180px";
  padding: "10px";
  font-family: "Cygnito Mono";
  font-weight: "600";
  font-size: "15px";
  margin-top: 20;
  margin-bottom: 15;
} */
/* .font-face-gm {
  font-family: "Cygnito Mono" !important;
} */

/* @media only screen and (max-width: 460px) {
  .font-face-gm {
    font-size: ;
  }
}  */

/* @media only screen and (min-width: 992px) {
  .img-container {
    width: 100%;
    display: flex;
    padding-top: 50px;
    padding-left: 50px;
    float: right !important;
  }

  .home-container {
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    padding-left: 20px;
  }
  .fingerprint {

    width: 50%;
    margin: 300px;
  }
} */
