body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "CygnitoMono";
  src: local("CygnitoMono"),
    url(/static/media/CygnitoMono.9ccc8a46.otf) format("truetype");
}

.home-container {
  /* position: relative;
  width: 100%; */
  font-family: "Roboto Mono", monospace;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.home-text {
  font-size: larger;
  font-family: "Roboto Mono", monospace;
  text-align: center;
}
.home-portfolio-img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.img-container {
  width: 100%;
  display: flex;
  padding-top: 200px;
}
.fingerprint {
  /* position:relative; */
  width: 180px;
  /* height: auto; */
  /* display: inline-block; */
  margin: auto;
}

/* .portfolio-link {
  display: "flex";
  text-decoration: "none";
  color: "black";
  border: "2px solid white";
  background-color: "white";
  justify-content: "center";
  align-items: "center";
  width: "180px";
  padding: "10px";
  font-family: "Cygnito Mono";
  font-weight: "600";
  font-size: "15px";
  margin-top: 20;
  margin-bottom: 15;
} */
/* .font-face-gm {
  font-family: "Cygnito Mono" !important;
} */

/* @media only screen and (max-width: 460px) {
  .font-face-gm {
    font-size: ;
  }
}  */

/* @media only screen and (min-width: 992px) {
  .img-container {
    width: 100%;
    display: flex;
    padding-top: 50px;
    padding-left: 50px;
    float: right !important;
  }

  .home-container {
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    padding-left: 20px;
  }
  .fingerprint {

    width: 50%;
    margin: 300px;
  }
} */

/* .navbar-toggler-icon {
  color: red;
} */
.custom-navbar {
  background-color: black !important;
  text-align: center;
}

.nav-logo {
  position: relative;
  width: 35px;
  margin-right: 25px;
  /* margin-left: 25px; *ƒ/
  /* border-radius: 50%; */
  display: inline-block;
}

.nav-company-name {
  /* color: rgb(63, 63, 63); */
  color: #ffffff;
  font-size: x-large;

  font-family: "Roboto Mono", monospace;
  padding-left: 20px;
  /* position:  */
  display: inline-block;
  position: relative;
  top: 5px;
}

.nav-company-name:hover {
  color: #b2b2b2;
}

.navbar-light {
  /* align-items: center; */
  box-shadow: none !important;
}

.nav-link {
  font-family: "Roboto Mono", monospace;
  color: white;
}

.nav-link-logo {
  padding: none !important;
  margin-top: 0 !important;
  text-decoration: none !important;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .nav-logo {
    width: 50px;
  }
  .nav-company-name {
  }
}

.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: whitesmoke !important;
}

.custom-dropdown {
}

.dropdown-menu {
  /* background-color: #1e1e1e !important; */
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.dropdown-item {
  /* color: #000000 !important; */
}

.dropdown-toggle {
  font-size: medium;
}

.dropdown-item:hover {
  background-color: #121212 !important;
}

.navbar-radius {
  /* border-radius: 15px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* display: block; */
}
.navbar-collapse {
  position: relative;
  right: 0;
}

.custom-navbar-brand {
  /* color: #000000 !important; */
}

.custom-navbar-brand:hover {
  color: #9e9e9e !important;
}

.navbar-nav {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .nav-company-name {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.hamburger-container {
  color: white !important;
}

.footer-container {
  width: 100% !important;
  /* position: absolute;
  bottom: 0; */
  /* margin: 0 !important; */
  height: 220px;
  background-color: black;
  text-align: center;
  font-family: "Roboto Mono", monospace;
  /* font-family: "Cygnito Mono"; */
  /* position: absolute;
  bottom: 0; */

  /* border-top-left-radius: 25pt;
  border-top-right-radius: 25pt; */
}

.footer-wrapper {
  padding-top: 20px;
}

.footer-text {
  color: white;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.footer-links {
  text-align: right;
}
.footer-link {
  text-decoration: none;
  color: darkgray;
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
}

.footer-company-icon {
  display: inline;
  width: 80px;
  margin-right: 5px;
  /* margin-bottom: 10px; */
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
  padding-bottom: 2vh;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: white;

  padding-bottom: 10px;
}

.footer-icon:hover {
  /* color: rgb(0, 153, 255); */
  color: #39a0ed;
}

.App {
  text-align: center;
}

* {
  text-transform: uppercase !important;
  font-family: "CygnitoMono";
  /* background-color: black !important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-face-gm {
  font-family: "CygnitoMono";
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.thumbs-wrapper {
  margin: 0px !important;
}
.portfolio-page {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  min-height: 100vh;
  background-color: black;
}
.portfolio-page::-webkit-scrollbar {
  /* height: 100vh; */

  display: none;
}

.proto-title {
  font-size: 1.2rem !important;
}

.carousel-container {
  /* height: 500px; */
  /* border: 1px solid black; */
  margin-top: 10px;
  text-align: center;
  /* text-align: center; */
  /* height: 100vw; */
  /* margin-bottom: 50vh; */
}
.carousel-item {
  /* height: 100%; */
}

.carousel .thumb img {
  /* width: 100% !important;
  height: 100% !important; */
}

.carousel .slide img {
  object-fit: contain;
  max-height: 50vh;
}

.carousel .slide:hover img {
  /* object-fit: contain;
    max-height: 50vh;
    width: auto; */
  /* transform: scale(1.5); */
}
.class-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  height: 100%;
  overflow-x: scroll;
  font-family: "CygnitoMono";
  /* new addition for change of font */
  color: white;
  font-size: 21px;
  font-weight: 600;
  /* max-width: 500px; */
  /* padding-left: 1000px; */
}
::-webkit-scrollbar {
  display: none;
}
.collection-piece-container {
  /* text-align: center; */
  /* max-width: 800px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* overflow-x: scroll; */
  align-items: center;
  color: gray;
}

.main-container {
  /* margin-bottom: 25px; */
  display: flex;
  max-width: 800px;
  min-width: 350px;
  justify-content: space-between;
  /* align-items: center; */
  /* max-width: 500px; */
}
.portfolio-container {
  margin-top: 60px;
}

.collection-title {
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 21px;
  font-weight: 600;
  /* color: black; */
  color: white;
  /* width: 1000px !important;
  height: 20px !important; */
  /* border: 2px solid black; */
  display: inline-block;
  white-space: nowrap;
  padding: 2px;

  font-family: "Roboto Mono", monospace;
  /* font-family: "Cygnito Mono"; */
}
/* .collection-title:hover {
  cursor: pointer;
  background-color: black;
  color: white;
} */
/* .collection-title-selected {
  color: white;
  background-color: black;
} */
.collection-header {
  margin-top: 5px;
  /* margin-left: 25px; */
  /* text-align: left; */
  /* align-self: baseline; */
  /* margin-right: 20; */
  /* width: 200px; */

  font-size: 14px;
  font-weight: 600;
  color: gray;
  font-family: "Roboto Mono", monospace;
  /* font-family: "Cygnito Mono"; */
}

.about-collection-button {
  border: 2px solid white;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  background-color: black;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  margin: 10;
  /* margin-left: 20px; */
  /* width: 150px; */
  /* display: inline; */
}
.about-collection-button p {
  display: inline;
  font-weight: 600;
  /* font-size: large; */
}
/* .direction-arrow-btn:hover {
  cursor: pointer;
  background-color: white !important;
  color: black !important;
} */
.about-collection-button:hover {
  cursor: pointer;
  /* background-color: black; */
  /* background-color: white !important; */
  border-color: blue;
  /* color: white; */
  color: blue;
}

.gallery-collection-button {
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  /* margin-left: 20px; */
  /* width: 150px; */
  /* display: inline; */
}
.gallery-collection-button p {
  display: inline;
  font-weight: 600;
  /* font-size: large; */
}
.gallery-collection-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.portfolio-about-container {
  text-align: center;
  /* margin-bottom: 100px; */
  /* font-family: "Roboto Mono"; */
  color: white !important;
  font-size: medium;
}

.portfolio-about-label {
  color: white;
}

.portfolio-full-image-container {
  position: fixed;
  background-color: rgb(0, 0, 0);
  z-index: 4;
  top: 0;
  height: 100vh;
  width: 100vw;
}
.full-size-btn {
  -webkit-text-decoration: "none";
          text-decoration: "none";
  border: "2px solid black";
  color: "black";
  padding: "5px";
}
.fluid {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
}
.fluid__image-container {
  flex: 0 0 30%;
}

.portfolio-item-about-img:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media (min-width: 480px) {
  .direction-arrow-btn:hover {
    cursor: pointer;
    background-color: white !important;
    color: black !important;
  }

  .fluid {
    flex-direction: row;
  }

  .fluid__image-container {
    flex: 0 0 30%;
  }
  .portfolio-container {
    padding-left: 13vw;
    padding-right: 13vw;
  }
}
.direction-arrow-btn {
  color: white;
}

.direction-arrow-btn:active {
  cursor: pointer;
  background-color: white;
  color: black;
}

@media only screen and (min-width: 600px) {
  .carousel .slide img {
    object-fit: contain;
    max-height: 70vh;
  }
}

.bio-text {
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
}

.bio-pic {
  max-width: 100%;
  padding: 40px;
}

.special {
  margin-top: 4vh;
  padding: 40px;
  /* margin: 20px; */
}

.bio-container {
  display: flex;
  margin-top: 15vh;
  flex-direction: column;
}

.bio-subcontainer {
  width: 100%;
  /* justify-content: ; */
}

@media only screen and (min-width: 600px) {
  .bio-container {
    flex-direction: row;
  }
  .bio-subcontainer {
    width: 50%;
    /* justify-content: ; */
  }

  .special {
    margin-top: 2vh;
    padding: 20px;
    /* margin: 20px; */
  }
  .bio-text {
    font-size: 1rem;
    font-family: "Roboto Mono", monospace;
    padding: 40px 70px;
    margin-top: 4vh;
  }
}

.events-container {
    position: relative;
    width: 100%;
}

.gallery-container {
    position: relative;
    width: 100%;
}
.contact-container {
  /* position: relative; */
  min-height: 100vh;

  padding-top: 10vh;
  /* margin-left: 0;
  margin-right: 0; */
  margin: auto;
  align-items: center;
  min-width: 100%;
  /* font-family: "Poppins", sans-serif; */
  /* text-align: center; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Roboto Mono";
  background-color: rgb(0, 0, 0);
  color: white;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
  max-width: 1000px;

  flex-direction: row;
}

.contact-header {
  margin-top: 57px;
  text-align: left !important;
}

.contact-h2 {
  margin: 15px;
  color: rgb(183, 183, 183);
  padding-left: 40px;
  padding-right: 40px;
}

.contact-h2-container {
  /* width: 100%; */
  /* margin-left: 100px;
  margin-right: 100px; */
}

.success-alert {
  background-color: #43a047;

  color: white;
}

.sending-alert {
  background-color: #42a5f5;
  color: white;
}

.sending-alert-text {
  /* display: inline-block; */
  position: absolute;
  top: 1vh;
  left: 34%;
}

.alert {
  margin-top: 15px;
  height: 50px;
  text-align: center;
  padding: 9px;
  border-radius: 0;
}

.fail-alert {
  background-color: #e53935;

  color: white;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}

.contact-img {
  top: 40vh;
}

.contact-btn:hover {
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  color: #efefef;
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.form-group {
  margin-bottom: 10px;
}

.form-message {
  height: 150px;
}
.get-in-touch-btn {
  background-color: rgb(0, 0, 0);
  text-decoration: none;
  border: 2px solid white !important;
  height: 50px;
  border-radius: 5px;
  /* padding: 30; */
}
.contact-btn {
  text-align: center;
  text-decoration: none !important;

  width: 100%;

  display: flex;
  margin: auto;
  margin: 5vh 0vh;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .contact-form {
    width: px;
    margin: 0 10%;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }

  .contact-h2 {
    /* margin: 35px 25%; */
  }

  /* .contact-container {
    margin: 0vh;
    margin
  } */
}

.team-container {
  background-color: black;
  min-width: 100%;
  padding-top: 12vh;
}

.team-wrapper {
  min-height: 100vh;
}

.category-img-container-2 {
  text-align: center;
  /* height: 22vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.category-img-2 {
  display: inline-block;
  width: 250px;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* border-radius: 100%; */
  margin-top: 30px;
}
.about-job-header {
  color: black;
}
.team-text {
  text-align: left;
  margin-bottom: 0;
  color: white;
  max-width: 1000px;
  /* margin-bottom: 20px; */

  /* added with font change */
  text-indent: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  font-size: 15px;
}

.indent {
  text-indent: 30px;
  color: white;
  text-align: justify;
  font-family: "Roboto Mono";
  padding: 10px 50px;
  font-size: medium;
  /* font-weight: 600; */
}

.about-download-button {
  border: 2px solid white;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  text-decoration: none;
  margin-top: 15px;
  /* margin-left: 20px; */
  /* width: 150px; */
  /* display: inline; */
}
.about-download-button p {
  display: inline;
  font-weight: 600;
  /* font-size: large; */
}
.about-download-button:hover {
  cursor: pointer;
  border-color: blue;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-name {
  /* flex-flow: column; */
  /* padding: 25px 0px; */
  margin: 0;
  padding: 0;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}
@media only screen and (min-width: 600px) {
  .team-text {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
}

