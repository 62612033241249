body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.thumbs-wrapper {
  margin: 0px !important;
}
.portfolio-page {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: hidden;
  min-height: 100vh;
  background-color: black;
}
.portfolio-page::-webkit-scrollbar {
  /* height: 100vh; */

  display: none;
}

.proto-title {
  font-size: 1.2rem !important;
}

.carousel-container {
  /* height: 500px; */
  /* border: 1px solid black; */
  margin-top: 10px;
  text-align: center;
  /* text-align: center; */
  /* height: 100vw; */
  /* margin-bottom: 50vh; */
}
.carousel-item {
  /* height: 100%; */
}

.carousel .thumb img {
  /* width: 100% !important;
  height: 100% !important; */
}

.carousel .slide img {
  object-fit: contain;
  max-height: 50vh;
}

.carousel .slide:hover img {
  /* object-fit: contain;
    max-height: 50vh;
    width: auto; */
  /* transform: scale(1.5); */
}
.class-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  overflow-x: scroll;
  font-family: "CygnitoMono";
  /* new addition for change of font */
  color: white;
  font-size: 21px;
  font-weight: 600;
  /* max-width: 500px; */
  /* padding-left: 1000px; */
}
::-webkit-scrollbar {
  display: none;
}
.collection-piece-container {
  /* text-align: center; */
  /* max-width: 800px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* overflow-x: scroll; */
  align-items: center;
  color: gray;
}

.main-container {
  /* margin-bottom: 25px; */
  display: flex;
  max-width: 800px;
  min-width: 350px;
  justify-content: space-between;
  /* align-items: center; */
  /* max-width: 500px; */
}
.portfolio-container {
  margin-top: 60px;
}

.collection-title {
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 21px;
  font-weight: 600;
  /* color: black; */
  color: white;
  /* width: 1000px !important;
  height: 20px !important; */
  /* border: 2px solid black; */
  display: inline-block;
  white-space: nowrap;
  padding: 2px;

  font-family: "Roboto Mono", monospace;
  /* font-family: "Cygnito Mono"; */
}
/* .collection-title:hover {
  cursor: pointer;
  background-color: black;
  color: white;
} */
/* .collection-title-selected {
  color: white;
  background-color: black;
} */
.collection-header {
  margin-top: 5px;
  /* margin-left: 25px; */
  /* text-align: left; */
  /* align-self: baseline; */
  /* margin-right: 20; */
  /* width: 200px; */

  font-size: 14px;
  font-weight: 600;
  color: gray;
  font-family: "Roboto Mono", monospace;
  /* font-family: "Cygnito Mono"; */
}

.about-collection-button {
  border: 2px solid white;
  color: white;
  width: fit-content;
  padding: 10px;
  background-color: black;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  margin: 10;
  /* margin-left: 20px; */
  /* width: 150px; */
  /* display: inline; */
}
.about-collection-button p {
  display: inline;
  font-weight: 600;
  /* font-size: large; */
}
/* .direction-arrow-btn:hover {
  cursor: pointer;
  background-color: white !important;
  color: black !important;
} */
.about-collection-button:hover {
  cursor: pointer;
  /* background-color: black; */
  /* background-color: white !important; */
  border-color: blue;
  /* color: white; */
  color: blue;
}

.gallery-collection-button {
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  width: fit-content;
  padding: 10px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  /* margin-left: 20px; */
  /* width: 150px; */
  /* display: inline; */
}
.gallery-collection-button p {
  display: inline;
  font-weight: 600;
  /* font-size: large; */
}
.gallery-collection-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.portfolio-about-container {
  text-align: center;
  /* margin-bottom: 100px; */
  /* font-family: "Roboto Mono"; */
  color: white !important;
  font-size: medium;
}

.portfolio-about-label {
  color: white;
}

.portfolio-full-image-container {
  position: fixed;
  background-color: rgb(0, 0, 0);
  z-index: 4;
  top: 0;
  height: 100vh;
  width: 100vw;
}
.full-size-btn {
  text-decoration: "none";
  border: "2px solid black";
  color: "black";
  padding: "5px";
}
.fluid {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
}
.fluid__image-container {
  flex: 0 0 30%;
}

.portfolio-item-about-img:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media (min-width: 480px) {
  .direction-arrow-btn:hover {
    cursor: pointer;
    background-color: white !important;
    color: black !important;
  }

  .fluid {
    flex-direction: row;
  }

  .fluid__image-container {
    flex: 0 0 30%;
  }
  .portfolio-container {
    padding-left: 13vw;
    padding-right: 13vw;
  }
}
.direction-arrow-btn {
  color: white;
}

.direction-arrow-btn:active {
  cursor: pointer;
  background-color: white;
  color: black;
}

@media only screen and (min-width: 600px) {
  .carousel .slide img {
    object-fit: contain;
    max-height: 70vh;
  }
}
