.bio-text {
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
}

.bio-pic {
  max-width: 100%;
  padding: 40px;
}

.special {
  margin-top: 4vh;
  padding: 40px;
  /* margin: 20px; */
}

.bio-container {
  display: flex;
  margin-top: 15vh;
  flex-direction: column;
}

.bio-subcontainer {
  width: 100%;
  /* justify-content: ; */
}

@media only screen and (min-width: 600px) {
  .bio-container {
    flex-direction: row;
  }
  .bio-subcontainer {
    width: 50%;
    /* justify-content: ; */
  }

  .special {
    margin-top: 2vh;
    padding: 20px;
    /* margin: 20px; */
  }
  .bio-text {
    font-size: 1rem;
    font-family: "Roboto Mono", monospace;
    padding: 40px 70px;
    margin-top: 4vh;
  }
}
