/* .navbar-toggler-icon {
  color: red;
} */
.custom-navbar {
  background-color: black !important;
  text-align: center;
}

.nav-logo {
  position: relative;
  width: 35px;
  margin-right: 25px;
  /* margin-left: 25px; *ƒ/
  /* border-radius: 50%; */
  display: inline-block;
}

.nav-company-name {
  /* color: rgb(63, 63, 63); */
  color: #ffffff;
  font-size: x-large;

  font-family: "Roboto Mono", monospace;
  padding-left: 20px;
  /* position:  */
  display: inline-block;
  position: relative;
  top: 5px;
}

.nav-company-name:hover {
  color: #b2b2b2;
}

.navbar-light {
  /* align-items: center; */
  box-shadow: none !important;
}

.nav-link {
  font-family: "Roboto Mono", monospace;
  color: white;
}

.nav-link-logo {
  padding: none !important;
  margin-top: 0 !important;
  text-decoration: none !important;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .nav-logo {
    width: 50px;
  }
  .nav-company-name {
  }
}

.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: whitesmoke !important;
}

.custom-dropdown {
}

.dropdown-menu {
  /* background-color: #1e1e1e !important; */
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.dropdown-item {
  /* color: #000000 !important; */
}

.dropdown-toggle {
  font-size: medium;
}

.dropdown-item:hover {
  background-color: #121212 !important;
}

.navbar-radius {
  /* border-radius: 15px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  /* display: block; */
}
.navbar-collapse {
  position: relative;
  right: 0;
}

.custom-navbar-brand {
  /* color: #000000 !important; */
}

.custom-navbar-brand:hover {
  color: #9e9e9e !important;
}

.navbar-nav {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .nav-company-name {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.hamburger-container {
  color: white !important;
}
