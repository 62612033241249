.team-container {
  background-color: black;
  min-width: 100%;
  padding-top: 12vh;
}

.team-wrapper {
  min-height: 100vh;
}

.category-img-container-2 {
  text-align: center;
  /* height: 22vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.category-img-2 {
  display: inline-block;
  width: 250px;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* border-radius: 100%; */
  margin-top: 30px;
}
.about-job-header {
  color: black;
}
.team-text {
  text-align: left;
  margin-bottom: 0;
  color: white;
  max-width: 1000px;
  /* margin-bottom: 20px; */

  /* added with font change */
  text-indent: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  font-size: 15px;
}

.indent {
  text-indent: 30px;
  color: white;
  text-align: justify;
  font-family: "Roboto Mono";
  padding: 10px 50px;
  font-size: medium;
  /* font-weight: 600; */
}

.about-download-button {
  border: 2px solid white;
  color: white;
  width: fit-content;
  padding: 10px;
  text-decoration: none;
  margin-top: 15px;
  /* margin-left: 20px; */
  /* width: 150px; */
  /* display: inline; */
}
.about-download-button p {
  display: inline;
  font-weight: 600;
  /* font-size: large; */
}
.about-download-button:hover {
  cursor: pointer;
  border-color: blue;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-name {
  /* flex-flow: column; */
  /* padding: 25px 0px; */
  margin: 0;
  padding: 0;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}
@media only screen and (min-width: 600px) {
  .team-text {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
}
