.contact-container {
  /* position: relative; */
  min-height: 100vh;

  padding-top: 10vh;
  /* margin-left: 0;
  margin-right: 0; */
  margin: auto;
  align-items: center;
  min-width: 100%;
  /* font-family: "Poppins", sans-serif; */
  /* text-align: center; */
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Roboto Mono";
  background-color: rgb(0, 0, 0);
  color: white;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
  max-width: 1000px;

  flex-direction: row;
}

.contact-header {
  margin-top: 57px;
  text-align: left !important;
}

.contact-h2 {
  margin: 15px;
  color: rgb(183, 183, 183);
  padding-left: 40px;
  padding-right: 40px;
}

.contact-h2-container {
  /* width: 100%; */
  /* margin-left: 100px;
  margin-right: 100px; */
}

.success-alert {
  background-color: #43a047;

  color: white;
}

.sending-alert {
  background-color: #42a5f5;
  color: white;
}

.sending-alert-text {
  /* display: inline-block; */
  position: absolute;
  top: 1vh;
  left: 34%;
}

.alert {
  margin-top: 15px;
  height: 50px;
  text-align: center;
  padding: 9px;
  border-radius: 0;
}

.fail-alert {
  background-color: #e53935;

  color: white;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}

.contact-img {
  top: 40vh;
}

.contact-btn:hover {
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  color: #efefef;
  transform: translateY(-4px);
}

.form-group {
  margin-bottom: 10px;
}

.form-message {
  height: 150px;
}
.get-in-touch-btn {
  background-color: rgb(0, 0, 0);
  text-decoration: none;
  border: 2px solid white !important;
  height: 50px;
  border-radius: 5px;
  /* padding: 30; */
}
.contact-btn {
  text-align: center;
  text-decoration: none !important;

  width: 100%;

  display: flex;
  margin: auto;
  margin: 5vh 0vh;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .contact-form {
    width: px;
    margin: 0 10%;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }

  .contact-h2 {
    /* margin: 35px 25%; */
  }

  /* .contact-container {
    margin: 0vh;
    margin
  } */
}
